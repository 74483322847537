exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isp-js": () => import("./../../../src/pages/isp.js" /* webpackChunkName: "component---src-pages-isp-js" */),
  "component---src-pages-licences-js": () => import("./../../../src/pages/licences.js" /* webpackChunkName: "component---src-pages-licences-js" */),
  "component---src-pages-mnoc-dmarc-js": () => import("./../../../src/pages/mnoc-dmarc.js" /* webpackChunkName: "component---src-pages-mnoc-dmarc-js" */),
  "component---src-pages-mnoc-trustpilot-js": () => import("./../../../src/pages/mnoc-trustpilot.js" /* webpackChunkName: "component---src-pages-mnoc-trustpilot-js" */),
  "component---src-pages-msp-js": () => import("./../../../src/pages/msp.js" /* webpackChunkName: "component---src-pages-msp-js" */),
  "component---src-pages-netprove-js": () => import("./../../../src/pages/netprove.js" /* webpackChunkName: "component---src-pages-netprove-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-web-terms-js": () => import("./../../../src/pages/web-terms.js" /* webpackChunkName: "component---src-pages-web-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-posts-list-js": () => import("./../../../src/templates/blog-posts-list.js" /* webpackChunkName: "component---src-templates-blog-posts-list-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-tags-list-js": () => import("./../../../src/templates/tags-list.js" /* webpackChunkName: "component---src-templates-tags-list-js" */)
}

